import moment from 'moment';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Box, Button, Grid, Tab, Tabs } from '@mui/material';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { DeleteModal, DrawerHeader, Loader } from '../../../components';
import {
  CANCEL_RESERVATION,
  GET_RESERVATION_PROPERTIES,
  NOTE_LIST,
} from '../../../graphql/api/reservation';
import { CustomSnackbar } from '../../../hoc/CustomSnackbar';
import { useAuth } from '../../../hooks/useAuth';
import {
  INoteTabData,
  IReservationContext,
  IReservationProvider,
} from '../../../models/reservation';
import RentalInformation from './generalTab/rentalInformation';
import { Messages, reservationStatus, Severity } from '../../../enum/enumList';
import { formatReservationId } from '../../../utils/formatReservationId';
//  context for whole Renter Modal
export const ReservationContext = createContext<IReservationContext>({} as IReservationContext);

const ReservationProvider = ({ children, snackbarShowMessage }: IReservationProvider) => {
  const { state } = useLocation();

  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [noteTabData, setNotesTabData] = useState<INoteTabData[]>([]);
  const [reservationData, setReservation] = useState({} as any);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, currentSeason } = useAuth();
  const storeId = user?.selectedStore;
  const reservationId = JSON.parse(sessionStorage.getItem('reservationId') as string);

  const [getReservationApiInfo, { data: reservationApiData, loading, refetch }] = useLazyQuery(
    GET_RESERVATION_PROPERTIES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [cancelReservationApi, { loading: cancelReservationLoading }] =
    useMutation(CANCEL_RESERVATION);

  const [getNoteList, { loading: getNoteListLoading }] = useMutation(NOTE_LIST);

  const getReservationInfo = () =>
    getReservationApiInfo({
      variables: {
        reservationId: reservationId ?? null,
        storeId,
      },
      onCompleted(data) {
        if (
          data?.getReservationProperties?.data?.reservation?.status === reservationStatus.pending
        ) {
          sessionStorage.clear();
          navigate('/reservations');
        }
      },
      onError() {
        sessionStorage.removeItem('reservationId');
        navigate('/reservations');
      },
    });

  // if redirection is provided in state then navigate to that tab
  useEffect(() => {
    if (state && state.tabIndex) {
      setCurrentTab(state.tabIndex);
    }
  }, [state]);

  // hit reservation details api
  useEffect(() => {
    if (!reservationId) navigate('/reservations');
    getReservationInfo();
    if (pathname === '/reservation/reservation-detail')
      getNoteList({
        variables: {
          storeId,
          reservationId,
        },
        onCompleted(data) {
          setNotesTabData(data?.listNotes?.data || []);
        },
      });
  }, [reservationId, pathname]);

  useEffect(() => {
    setReservation(reservationApiData?.getReservationProperties?.data);
  }, [reservationApiData]);

  const value = useMemo(
    () => ({
      reservationData,
      general: reservationApiData?.getReservationProperties?.data?.reservation,
      waiver: {},
      noteTabData,
      setNotesTabData,
      payment: {},
      communication: reservationApiData?.getReservationProperties?.data?.reservation,
      loading,
      setReservation,
      snackbarShowMessage,
      getReservationInfo,
      currentTab,
      refetch,
    }),
    [reservationData, loading, snackbarShowMessage, getReservationInfo, noteTabData, currentTab],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);

  function a11yProps(index: number) {
    return {
      id: `packages-tabpanel-${index}`,
      'aria-controls': `packages-tabpanel-${index}`,
    };
  }

  const techStationClick = () => {
    // check if   try to aceess tech station page before reservation is started
    if (
      moment(reservationApiData?.getReservationProperties?.data?.reservation?.startDate).isAfter(
        new Date(),
      ) &&
      (reservationApiData?.getReservationProperties?.data?.reservation?.isPickupDayBefore
        ? moment(reservationApiData?.getReservationProperties?.data?.reservation?.startDate)
            .subtract(1, 'days')
            .isAfter(new Date())
        : true)
    ) {
      if (snackbarShowMessage)
        return snackbarShowMessage(
          'Cannot assign equipment before reservation start date',
          Severity.Error,
        );
    }

    // check is waiver is signed or not
    if (!reservationApiData?.getReservationProperties?.data?.reservation?.isWaiverSigned) {
      if (snackbarShowMessage)
        return snackbarShowMessage(Messages.assignEquipmentError, Severity.Error);
    }

    navigate('/reservation/tech-listing');
  };

  const handleCancelReservation = () => {
    cancelReservationApi({
      variables: {
        reservationId: reservationApiData?.getReservationProperties?.data?.reservation?.id,
        storeId: reservationApiData?.getReservationProperties?.data?.reservation?.storeId,
        isAdmin: true,
      },
      onCompleted(data) {
        setCancelReservationModal(false);
        if (snackbarShowMessage)
          snackbarShowMessage(data.cancelReservation.message, Severity.Success);

        setTimeout(() => {
          navigate('/reservations');
        }, 2000);
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  if (loading || !reservationApiData || getNoteListLoading) return <Loader />;

  if (pathname === '/reservation/rental-detail')
    return (
      <ReservationContext.Provider value={value}>
        <RentalInformation />
      </ReservationContext.Provider>
    );
  return (
    <ReservationContext.Provider value={value}>
      <Box component='main' className='wrapper' sx={{ flexGrow: 1, p: 4 }}>
        <DrawerHeader />
        <Grid container className='wrapper__inner-content wrapper__inner-content--tabs-padding-sm'>
          <Box
            component='div'
            className='inner-content__heading-outer inner-content__heading-outer--btn inner-content__heading-outer--border-btm inner-content__heading-outer--mob-flex-col inner-content__tab-col'
          >
            <Box className='disp-flex'>
              <Box component='h1'>Reservation Detail</Box>
              <Box component='span' className='heading-outer__badge heading-outer__badge--progress'>
                <strong>
                  {formatReservationId(
                    reservationData?.reservation?.id,
                    reservationData?.reservation?.storeId,
                  ) || '--'}
                </strong>
              </Box>
              <Box
                component='span'
                className={`heading-outer__badge ${
                  reservationData?.reservation?.status === reservationStatus.processing
                    ? 'heading-outer__badge--progress'
                    : reservationData?.reservation?.status === reservationStatus.completed
                    ? 'heading-outer__badge--completed'
                    : reservationData?.reservation?.status === reservationStatus.cancelled
                    ? 'heading-outer__badge--cancelled'
                    : reservationData?.reservation?.status === reservationStatus.pending
                    ? 'heading-outer__badge--draft'
                    : ''
                }`}
              >
                {reservationData?.reservation?.status === reservationStatus.processing
                  ? 'In Progress'
                  : reservationData?.reservation?.status === reservationStatus.completed
                  ? 'Completed'
                  : reservationData?.reservation?.status === reservationStatus.cancelled
                  ? 'Cancelled'
                  : reservationData?.reservation?.status === reservationStatus.pending
                  ? 'In Draft'
                  : ''}
              </Box>
            </Box>
            <Box component='div' className='heading-outer__btn-wrap'>
              {reservationData?.reservation?.status === reservationStatus.processing && (
                <Button
                  type='button'
                  className='delete-btn'
                  onClick={() => setCancelReservationModal(true)}
                >
                  <CancelOutlinedIcon /> Cancel Reservation
                </Button>
              )}
              {reservationData?.reservation?.status === reservationStatus.processing && (
                <Button type='button' className='primary-btn' onClick={techStationClick}>
                  <EngineeringOutlinedIcon /> Tech Station
                </Button>
              )}
            </Box>
          </Box>
          {reservationData?.reservation?.manualResrvUpdatedOn && (
            <Alert severity={'info'} className='mb-1 w-100'>
              {Messages.reservChangeManual}
            </Alert>
          )}
          <Box className='tab-header tab-header--more-tabs'>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label='packages tab'>
              <Tab label='General' {...a11yProps(0)} />
              <Tab label='Waiver' {...a11yProps(1)} />
              <Tab label='Notes' {...a11yProps(2)} />
              <Tab label='Payment' {...a11yProps(3)} />
              <Tab label='Communication' {...a11yProps(4)} />
              <Tab label='Feedback' {...a11yProps(5)} />
            </Tabs>
          </Box>
          {children}{' '}
        </Grid>
      </Box>

      <DeleteModal
        modelOpen={cancelReservationModal}
        setModelOpen={() => setCancelReservationModal(false)}
        onDelete={() => handleCancelReservation()}
        loading={cancelReservationLoading}
        buttonText='Cancel Reservation'
        label={`Are you sure you want to cancel ${
          formatReservationId(
            reservationApiData?.getReservationProperties?.data?.reservation?.id,
            reservationApiData?.getReservationProperties?.data?.reservation?.storeId,
          ) || '--'
        } reservation?`}
      />
    </ReservationContext.Provider>
  );
};

export default CustomSnackbar(ReservationProvider);
